.project-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}
.project-carousel{
    width: 100%;
    margin: 2vw;
}

.hover-about{
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
}

.about-me-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-height: 20vh;
}

.about-me-content {
    max-width: 800px;
    max-height: 50vh;
    text-align: flex-start;
    padding: 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.02em;
    word-spacing: 0.1em;
    font-family: Arial, sans-serif;
    border-radius: 8px;
    overflow: none;
    scroll-behavior: smooth;
}

.carousel-container{
    max-height: 20%;
}
    
.carousel-info-container {
    display: flex;
    justify-content: space-between;
    margin: 1vw;
  }
  
  @media (max-width: 600px) { /* Adjust the breakpoint as needed */
    .carousel-info-container {
      display: block;
    }
    .info-container{
        max-width: 100%;
        background-color: rgb(224, 232, 239, 0.4);
        max-height: 300px;
        overflow: scroll;
        margin: 1vh;
        padding: 1vw;
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.4);
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.02em;
        word-spacing: 0.1em;
        overflow: scroll;
    }
  }
  @media (min-width: 500px){
    .info-container{
        max-width: 30%;
        background-color: rgb(224, 232, 239, 0.4);
        max-height: 400px;
        overflow: scroll;
        margin: 1vw;
        padding: 1vw;
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.4);
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.02em;
        word-spacing: 0.1em;
        overflow: scroll;
    }
  } 
.carousel-info-links-container{
    margin: 1vw;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.logo-container{
    display: flex;
}
.react-logo-invisible,
.postgres-logo-invisible {
  opacity: 0;
}

 .react-logo-visible,
 .postgres-logo-visible {
  opacity: 1;
  transition: opacity 10s ease-in-out;
  height: 50px;
  margin: 1vw;
}
.code-link{
    text-decoration: none;
    font-weight: 700;
    color: #000;
    margin-bottom: 2vh;
    margin-right: 5vw;
    margin-left: 1vw;
}
.code-link:hover{
    transform: scale(1.2);
}
.link-container{
    width: 50%;
    display: flex;
    align-items: center;
}
