@media (max-width: 600px) {
  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30vh;
    width: 100%;
    font-size: 3vh;
    font-family: 'Righteous', cursive;

  }
}

@media (min-width: 599px) {
  .header {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    height: 30vh;
    width: 100%;
    margin: 1vw;
    font-size: 5vh;
    font-family: 'Righteous', cursive;
  }

}