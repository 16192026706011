
@media (max-width: 600px) {
  .links-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 5%;
    margin-right: 10%;
    cursor: pointer;
  }
  
  .link-item {
    padding: 10px;
    color: #333;
    transition: transform 0.3s, background-color 0.3s;
    font-family: 'Righteous', cursive;
    font-size: 5vw;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    left: -3vw;
  }
}
@media (min-width: 599px) {
.links-container {
  display: inline-block;
  margin-right: 10%;
  width: 100%;
  justify-self: start;
  cursor: pointer;

}

.link-item {
  color: black;
  display: block;
  padding: 15px;
  font-family: 'Righteous', cursive;
  transition: transform 0.3s, background-color 0.3s;
  font-size: 4vh;
  cursor: pointer;
  text-decoration: none;
}
}

.link-item:hover {
  color: rgb(157, 157, 157);
  cursor: pointer;
}
.link-iem:hover .text-1{
  display: block;
}
.text-1 {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 10px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 4px;
}
