  .name-container{
    display: block;
  }
  .falling-letter {
    font-size: 2vh;
    animation: fallAnimation 2s forwards;
    position: relative;
    top: -100vh;
  }
  
  @keyframes fallAnimation {
    0% {
      top: -100vh;
      transform: translateY(-100%);
    }
    40% {
      top: 0;
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(0);
    }
    100% {
      top: 0;
      transform: translateY(0);
    }
  }
  
  @keyframes stayAnimation {
    0% {
      top: 0;
    }
    100% {
      top: 0;
    }
  }
  
  .falling-letter {
    animation: fallAnimation 2s ease-in-out 1, stayAnimation 2s ease-in-out infinite;
  }
  
  .falling-letter:nth-child(1) {
    animation-delay: 0.2s;
  }
  
  .falling-letter:nth-child(2) {
    animation-delay: 0.4s;
  }
  
  .falling-letter:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  .falling-letter:nth-child(4) {
    animation-delay: 0.8s;
  }
  
  .falling-letter:nth-child(5) {
    animation-delay: 1.0s;
  }
  
  .falling-letter:nth-child(6) {
    animation-delay: 1.2s;
  }
  
  .falling-letter:nth-child(7) {
    animation-delay: 1.4s;
  }
  
  .falling-letter:nth-child(8) {
    animation-delay: 1.6s;
  }
  
  .falling-letter:nth-child(9) {
    animation-delay: 1.8s;
  }
  
  .falling-letter:nth-child(10) {
    animation-delay: 2.0s;
  }
  
  .falling-letter:nth-child(11) {
    animation-delay: 2.2s;
  }
  
  .falling-letter:nth-child(12) {
    animation-delay: 2.4s;
  }
  
  .falling-letter:nth-child(13) {
    animation-delay: 2.6s;
  }  
  .falling-letter:nth-child(14) {
    animation-delay: 2.8s;
  }
  .falling-letter:nth-child(15) {
    animation-delay: 3.0s;
  }
  .falling-letter:nth-child(16) {
    animation-delay: 3.2s;
  }
  .falling-letter:nth-child(17) {
    animation-delay: 3.4s;
  }
  .falling-letter:nth-child(18) {
    animation-delay: 3.6s;
  }
  .falling-letter:nth-child(19) {
    animation-delay: 3.8s;
  }
  