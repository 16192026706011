
.App {
  text-align: center;
}



body {
  background: linear-gradient(to bottom, rgba(200, 200, 200, 0.2), rgba(240, 240, 240, 0.5), rgba(255, 255, 255, 0.8), rgba(240, 240, 240, 0.5), rgba(200, 200, 200, 0.2));
  background-size: 100% 600%;
  animation: lavaLampAnimation 10s infinite;
  transition: background-position 0.5s ease-in-out;
}

@keyframes lavaLampAnimation {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}

body:hover {
  background-position: 0% 0%;
}
