.home-split {
    margin: 3vw;
    padding: 2%;
    height: 100vh;
    width: auto;
    border: 1px black solid;
    display: grid;
    grid-template-rows: 15vh 1fr 1fr ;
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;
    overflow: hidden;
    gap:5vh ;
  }
  
 .drawer{
    grid-area: 2/3/3/3;
    justify-self: end;
 }
 .header{
    grid-column: 1/ span 2;;
 }

.links {
    grid-area: 2/1/3/2;
    grid-column-start: 1;
    grid-row-start: 2;
}
.about{
    grid-area: 3/3/2/3;
    grid-column-end: 3;
    grid-row: 2/ span 3;
    margin-right: 3vw;
}
#about-me {
        grid-area: 3/3/2/3;
    grid-column-end: 3;
    grid-row: 2/ span 3;
    /* margin-right: 3vw;*/
    max-width: 800px;
     max-height: 50vh; 
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.02em;
    word-spacing: 0.1em;
    font-family: Arial, sans-serif;
    border-radius: 8px;
    overflow: scroll;
    scroll-behavior: smooth; 
}