.body-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-container {
  align-self: flex-end;
  display: inline-block;
}

.text-bubble {
  position: absolute;
  transform: translateX(-50%);
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 999;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s ease-in;
}
@media(min-width: 599px){
  .button-container:hover .text-bubble {
    visibility: visible; /* Show the bubble on hover */
    opacity: 1;
  }
}


.chevron-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 2.5px solid rgb(244, 242, 242);
  background-color: #f0f0f0;
  border-radius: 10px;
  color: rgb(7, 6, 6);
  font-size: 28px;
  width: 2vw;
  align-self: flex-end;
}
.chevron-button-invisible {
display: none;
}

.EZDrawer .EZDrawer__checkbox {
  display: none;
}

.EZDrawer .EZDrawer__checkbox:checked~.EZDrawer__overlay {
  display: block;
  opacity: 1;
}

.EZDrawer .EZDrawer__checkbox:checked~.EZDrawer__container {
  visibility: visible;
  transform: translate3d(0, 0, 0) !important;
}

.EZDrawer .EZDrawer__overlay {
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(147, 144, 144, 0.5) !important;
}
@media (max-width: 600px){
  .social-links {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}
.social-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
@media (max-width: 600px) {
  .social-links img {
    max-height: 6vh;
    width: auto;
    padding: 7vh;
    margin: 1%;
  }
}
.social-links img {
  height: 15vh;
  width: auto;
  padding: 7vh;
  margin-right: 1.5vh;
}

.social-links img:hover {
  transform: scale(1.1);
}
.EZDrawer .EZDrawer__container {
  position: fixed;
  visibility: hidden;
  background: rgba(201, 195, 195, 0.5);
  transition: all;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.4);
  max-width: 25vw
}
